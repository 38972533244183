@media only screen and (min-width: 1200px) {
  .footerurlsect {
    margin-top: 90vh;
    position: absolute;
    z-index: 1;
    margin-left: 39%;
    color: white;
  }
}

.footerurl
{
    color:white;
}
.footerurl:hover
{
     color: #2EC4F2 !important;
}

.modal {
 
  width: 100% !important;
  height: 90% !important;
 
}
.fs-20
{
 font-size: 20px;
}
.maintag
{
  --bs-gutter-x: 0rem;
}
.ultagliststyle
{
  list-style-type: none;
}

.mh-100vh
{
  min-height: 100vh;
}
.mb-0
{
  margin-bottom: 0px !important;
}

.colorfff
{
  color: #fff;
}
.bgblack
{
  background-color: black;
}
.pt-110
{
  padding-top: 110px;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}


/* animatio delay css  */

.animate {
  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
  animation-delay: .5s;
  animation-fill-mode: backwards;
}
/* shorthand version:
animation: animation-name .75s cubic-bezier(.26, .53, .74, 1.48) .5s backwards;
*/

/* Pop In */
.pop { animation-name: animate-pop; }

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
/* Slide In */
.slide { animation-name: animate-slide; }

@keyframes animate-slide {
  0% {
    opacity: 0;
    transform: translate(0, 40px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
/* Slide Left */
.slide-left { animation-name: animate-slide-left; }

@keyframes animate-slide-left {
  0% {
    opacity: 0;
    transform: translate(-40px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}


.delay-1 {
  animation-delay: 70ms;
}
.delay-12 {
  animation-delay: 140ms;
}
.delay-13 {
  animation-delay: 210ms;
}
.delay-14 {
  animation-delay: 280ms;
}
.delay-15 {
  animation-delay: 350ms;
}
.delay-16 {
  animation-delay: 420ms;
}
.delay-17 {
  animation-delay: 490ms;
}
.delay-18 {
  animation-delay: 0.8s;
}


/* .spanlable:hover {
  
  text-decoration: underline;
  text-underline-offset: 50%;
  text-decoration-color: #99ff33; 
  /* width: 10%;
  background: #99ff33; */

/* } */ 
.spanlable {
  position: relative;
  cursor: pointer;
}

.spanlable::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #99ff33; 
  transition: width 0.2s ease-in-out;
}

.spanlable:hover::before {
  width: 50%;
}



